"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorProvider = void 0;
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var ColorUtils_1 = require("@CommonFrontendBackend/StyleServiceV2/ColorUtils");
var ColorProvider = (function () {
    function ColorProvider(options) {
        this.presetColors = [];
        this.presetColors = options.presetColors;
    }
    ColorProvider.prototype.getPresetColors = function () {
        return this.presetColors;
    };
    ColorProvider.prototype.decorateColor = function (color) {
        var sanitizedColor = (0, GetBlockStyle_1.sanitizeColor)(color, this.presetColors);
        return (0, ColorUtils_1.isValidColor)(sanitizedColor.value) ? sanitizedColor : { value: "", themeIndex: null };
    };
    return ColorProvider;
}());
exports.ColorProvider = ColorProvider;
