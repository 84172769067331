"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compileBlockStyleVars = void 0;
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var dynamic_1 = require("@vanilla-extract/dynamic");
var BlockStyles_css_1 = require("Styles/BlockStyles/BlockStyles.css");
var ListItem_css_1 = require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/ListItem.css");
var BlockStyleTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/BlockStyleTypes");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var lodash_1 = require("lodash");
var compileBlockStyleVars = function (block, colors) {
    var _a, _b, _c, _d, _e;
    var _f, _g, _h, _j;
    var styleVars = {};
    var getColorFn = (0, GetBlockStyle_1.GetColor)(colors);
    if (block.style && block.style.blockType !== BlockTypes_1.BlockType.Source) {
        Object.assign(styleVars, (_a = {}, _a[ListItem_css_1.listItemSharedVars.blockLevelFontSize] = (0, GetBlockStyle_1.getSize)(block.style.p.size), _a));
    }
    if (hasStyle(block.style)) {
        if (block.style.background) {
            var backgroundColor = getColorFn(block.style.background);
            Object.assign(styleVars, (_b = {},
                _b[BlockStyles_css_1.blockStyleVars.backgroundColor] = backgroundColor,
                _b));
        }
        if ((0, BlockStyleTypes_1.isTextStyle)(block.style)) {
            if (block.style.backgroundUrl && block.style.backgroundType === "image") {
                Object.assign(styleVars, (_c = {},
                    _c[BlockStyles_css_1.blockStyleVars.backgroundImage] = "url(".concat(block.style.backgroundUrl, ")"),
                    _c));
            }
            if (block.style.backgroundVideoThumbnail && block.style.backgroundType === "video") {
                Object.assign(styleVars, (_d = {},
                    _d[BlockStyles_css_1.blockStyleVars.backgroundVideoThumbnail] = "url(".concat(block.style.backgroundVideoThumbnail, ")"),
                    _d));
            }
            Object.assign(styleVars, (_e = {},
                _e[BlockStyles_css_1.blockStyleVars.backgroundTintBlendMode] = ((_f = block.style) === null || _f === void 0 ? void 0 : _f.tintBlendMode) === commonTypes_1.TintBlendModeTypes.Blend ? "multiply" : "normal",
                _e[BlockStyles_css_1.blockStyleVars.backgroundTintColor] = getColorFn((_g = block.style) === null || _g === void 0 ? void 0 : _g.tintColor),
                _e[BlockStyles_css_1.blockStyleVars.minBlockHeight] = blockHeight((_j = (_h = block.style) === null || _h === void 0 ? void 0 : _h.blockHeight) !== null && _j !== void 0 ? _j : "auto"),
                _e));
        }
    }
    return (0, dynamic_1.assignInlineVars)(styleVars);
};
exports.compileBlockStyleVars = compileBlockStyleVars;
var blockHeight = function (setting) {
    switch (setting) {
        case "small": {
            return "33vh";
        }
        case "medium": {
            return "65vh";
        }
        case "large": {
            return "100vh";
        }
        default: {
            return "auto";
        }
    }
};
var hasStyle = function (style) {
    return !!style && !(0, lodash_1.isEmpty)(style);
};
